.ourProjectsList {
    width: 100%;
    height: 180vh;
    position: relative;

}

.ourProjectsList::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 70%;
    background-color: #111111;
    display: block;
    width: 100%;
}


.z-index-2 {
    z-index: 2;
}

.ourProjectsList-wrapper {
    z-index: 2;
    width: 100%;
    height: 100%;
    margin-top: 150px;
}

.ourProjectsList-title {
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
}

.ourProjectsList-title h1 {
    color: #111111;
    font-size: 48px;
    line-height: 125%;
}

.ourProjectsList-title h1::before {
    content: "";
    display: block;
    height: 2px;
    width: 100px;
    background: #E7BB4B;
    right: 32%;
    top: 50%;
    position: absolute;
}

.ourProjectsList-title h1::after {
    content: "";
    display: block;
    height: 2px;
    width: 100px;
    background: #E7BB4B;
    left: 32%;
    top: 50%;
    position: absolute;
}

.ourProjectsList-subtitle {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.ourProjectsList-subtitle h2 {
    color: #828282;
    font-size: 14px;
    line-height: 125%;
}

.ourProjectsList-search {
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    background: #FCFAF9;
    border-radius: 8px;
    margin-top: 50px;
}

.ourProjectList-search-items {
    width: 100%;
    padding: 20px 10px 20px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ourProjectList-search-item {
    width: 20%;
    display: grid;
    justify-content: center;
    align-items: center;
}

.ourProjectList-search-item-title h2 {
    font-size: 12px;
    line-height: 18px;
    color: #111111;
}


.ourProjectList-search-item-input button {
    border: 0px;
    background-color: transparent;
    border-bottom: 2px solid rgba(231, 187, 75, 0.32);
    padding-bottom: 5px;
    
}

.ourProjectList-search-item-input button::after {
    content: "|";
    margin-left: 20px;
}

.search-btn {
    font-size: 14px;
    color: #ffffff;
    line-height: 14px;
    background: linear-gradient(180deg, #35393E 0%, #050810 74.42%);
    border-radius: 9.28725px;
    padding: 15px 70px 15px 70px;
    border: 0px;
}

.ourProjectList-list-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin-top: 40px;
}

.ourProjectList-list-item {
    width: 100%;
    height: 680px;
    border-radius: 8px;
    background-color: #ffffff;
}

.ourProjectList-list-item-pic {
    width: 100%;
    height: 50%;
}

.ourProjectList-list-item-pic img {
    width: 100% !important;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.ourProjectList-list-item-info {
    width: 100%;
    height: 50%;
}

.ourProjectList-list-item-info-wrapper {
    width: 100%;
    height: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ourProjectList-item-info-title h1 {
    font-size: 24px;
    line-height: 125%;
    color: #111111;
}

.ourProjectList-item-info-description {
    padding-top: 20px;
}

.ourProjectList-item-info-description h2 {
    font-size: 14px;
    line-height: 125%;
    color: #828282;
}

.ourProjectList-item-info-details {
    width: 100%;
    height: 50px;
    margin-top: 20px;
    background-color: #FCFAF9;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ourProjectList-item-info-details span:first-child {
    margin-left: 20px;
}

.ourProjectList-item-info-details span:last-child {
    margin-right: 20px;
}

.ourProjectList-item-info-details span {
    font-size: 16px;
    line-height: 100%;
    color: #847E68;
}

.ourProjectList-item-info-price-and-btn {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}


.ourProjectList-item-info-price span {
    color: #111111;
    font-size: 24px;
}

.ourProjectList-item-info-location span {
    color: #828282;
    font-size: 12px;
}

.ourProjectList-item-info-btn-wrapper button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 60px;
    background: linear-gradient(180deg, #35393E 0%, #050810 74.42%);
    border-radius: 9.28725px;
    border: 0px;
    cursor: pointer;
    font-size: 16px;
    color: #ffffff;
}

/* Responsive */

@media only screen and (max-width: 1400px) {
    .ourProjectsList {
        width: 100%;
        height: 270vh;
        position: relative;
    }
    
    .ourProjectsList::before{
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 75% !important;
        background-color: #111111;
        display: block;
        width: 100%;
    }
    
    

    .ourProjectList-list-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        margin-top: 40px;
    }


    .ourProjectsList::before{
        height: 102vh;
    }
 
}

@media only screen and (max-width: 900px) {

    .ourProjectsList {
        width: 100%;
        height: 500vh;
        position: relative;
        margin-top: 200px;
    }
    
    .ourProjectsList::before{
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 88% !important;
        background-color: #111111;
        display: block;
        width: 100%;
    }

 
  
    .ourProjectList-list-wrapper {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        margin-top: 40px;
    }


    .ourProjectsList-subtitle h2 {
        text-align: center;
    }

    .ourProjectList-item-info-title h1 {
        font-size: 16px
    }

    .ourProjectList-item-info-description h2 {
        font-size: 12px;
    }

    .ourProjectList-item-info-details span {
        font-size: 14px
    }

    .ourProjectList-item-info-price span {
        font-size: 16px;
    }

    .ourProjectList-item-info-btn-wrapper button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 17px 35px;
        background: linear-gradient(180deg, #35393E 0%, #050810 74.42%);
        border-radius: 9.28725px;
        border: 0px;

        font-size: 14px;
        color: #ffffff;
    }

    .ourProjectsList::before{
        height: 360vh;
    }
}

::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}