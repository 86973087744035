/*video*/
.video {
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  position: fixed;
  left: 25px;
  bottom: 25px;
  width: 120px;
  height: 185px;
  border-radius: 20px;
  z-index: 9999;
}

.video-active {
  opacity: 1;
  visibility: visible;
}

.goster {
  transition: 1s;
  opacity: 1;
}
.video video {
  position: absolute;
  z-index: 0;
  object-fit: cover;
  width: 100%;
  border: 3px solid #e7bb4b;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
}

.kucult {
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  width: 30px;
  height: 30px;
  border-radius: 30px;
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 47px;
  
}
.kucult {
  top: 10px;
}

.kck{
    color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    display: grid;
    place-items: center;
    transform: scaleX(1.5);
    cursor: pointer;
}



.video-big {
  transition: 0.4s;
  width: 310px !important;
  height: 390px !important;
}
.video-big .ses,
.video-big .kucult {
  display: block;
}

.ara {
  opacity: 0;
  visibility: hidden;
  margin: 0;
  background: #e7bb4b;
  position: absolute;
  bottom: 0%;
  left: 50%;
  box-sizing: border-box;
  padding: 15px;
  width: 70%;
  text-align: center;
  text-decoration: none;
  border-radius: 20px;
  color: #fff;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.video-big .ara {
  animation-name: fixed-video;
  animation-duration: .5s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-delay: 10s;
  animation-fill-mode: forwards;
}
/*video*/

@keyframes fixed-video {
  0% {
    opacity: 0;
    visibility: hidden;
    bottom: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
    bottom: 5%;
  }
}

@media only screen and (max-width: 750px) {
  .video {
    left: 10px;
    bottom: 20px;
    width: 120px;
    height: 200px;
  }
  .video-big{
    width: 330px !important;
    height: 390px !important;
  }
}

