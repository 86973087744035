.ourProjects {
  background-color: #fcfaf9;
  width: 100%;
  height: 100vh;
  z-index: 1;
  background: rgb(252, 250, 249);
  background: linear-gradient(
    0deg,
    rgba(252, 250, 249, 1) 0%,
    rgba(255, 255, 255, 1) 150%
  );
  margin-top: -120px;
}

.ourProjects-wrapper {
  width: 100%;
  height: 100%;
  padding: 150px 0px 150px 0px;
}

.ourProjects-title {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.ourProjects-title h1 {
  color: #111111;
  font-size: 48px;
  line-height: 125%;
}

.ourProjects-title h1::before {
  content: "";
  display: block;
  height: 2px;
  width: 100px;
  background: #e7bb4b;
  right: 32%;
  top: 50%;
  position: absolute;
}

.ourProjects-title h1::after {
  content: "";
  display: block;
  height: 2px;
  width: 100px;
  background: #e7bb4b;
  left: 32%;
  top: 50%;
  position: absolute;
}

.ourProjects-subtitle {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.ourProjects-subtitle h2 {
  color: #828282;
  font-size: 14px;
  line-height: 125%;
}

.ourProjects-content-wrapper {
  display: flex;
  width: 100%;
  padding-top: 100px;
}

.ourProjects-categories {
  width: 30%;
  padding-top: 70px;
  position: relative;
}

.ourProjects-categories-item-active {
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  align-items: center;
}

.ourProjects-categories-item-active::after {
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  background: #e7bb4b;
  left: 0;
  bottom: 0;
  position: absolute;
  opacity: 0.24;
}

.ourProjects-categories-item-active img {
  display: block;
}

.ourProjects-categories-item-active .number {
  font-size: 32px;
  color: #111111;
  line-height: 150%;
  margin: 0px 20px 0px 20px;
}

.ourProjects-categories-item-contents {
  display: grid;
}

.ourProjects-categories-item-contents h3 {
  font-size: 12px;
  color: #d2d2d2;
  line-height: 150%;
}

.ourProjects-categories-item-contents h2 {
  font-size: 16px;
  color: #828282;
  line-height: 150%;
}

/* Deactive Items */
.ourProjects-categories-item {
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  align-items: center;
  cursor: pointer;
}

.ourProjects-categories-item::after {
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  background: #e7bb4b;
  left: 0;
  bottom: 0px;
  position: absolute;
  opacity: 0.24;
}

.ourProjects-categories-item img {
  display: none;
}

.ourProjects-categories-item .number {
  font-size: 32px;
  color: #111111;
  opacity: 0.32;
  line-height: 150%;
  margin: 0px 20px 0px 0px;
}

.ourProjects-categories-item .ourProjects-categories-item-contents {
  font-size: 16px;
  color: #111111;
  line-height: 150%;
  opacity: 0.32;
}
/* Deactive Items */

.ourProjects-projects {
  width: 70%;
  margin-left: 50px;
  height: 500px;
  background-color: #111111;
  border-radius: 8px;
}

.projects-wrapper {
  padding: 40px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.project-info {
  width: 38%;
  position: relative;
}

.project-info h2 {
  font-size: 24px;
  line-height: 125%;
  color: #ffffff;
}

.project-info h3 {
  margin-top: 15px;
  font-size: 16px;
  line-height: 125%;
  color: #d2d2d2;
}


.project-info-details {
  display: flex;
  margin-top: 10px;
}

.project-info-details span {
  color: #828282;
  margin-right: 4px;
}

.project-info-btns {
  margin-top: 20px;
}

.project-info-btns .btn {
  padding: 15px 40px 15px 40px;
  margin-right: 10px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
  outline: 0px;
  border: 0px;
}

.project-info-btns .btn-active {
  padding: 15px 40px 15px 40px;
  background: rgba(255, 255, 255, 1);
  margin-right: 10px;
  outline: 0px;
  border: 0px;
  border-radius: 8px;
}

.btn svg, .btn-active svg {
  color: #111111;
}

.project-info-bars {
  display: flex;
  position: absolute;
  bottom: 5px;
  left: 0;
}

.bar-active {
  width: 68px;
  height: 4px;
  border-radius: 8px;
  background-color: #e7bb4b;
  margin-right: 5px;
}

.bar {
  width: 38px;
  height: 4px;
  border-radius: 8px;
  background-color: #828282;
  margin-right: 5px;
}

.project-pics {
  width: 60%;
  height: 100%;
}

.project-pics .project-pic {
  width: 100%;
  height: 100%;
}

.project-pics .project-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

/* desktop Disable */
.ourProjects-categories-mobile {
  display: none;
}

.mobile-categiroies-title {
  display: none;
}
/* desktop Disable */

/* responsive */

@media only screen and (max-width: 1140px) {
  .ourProjects-title h1::before {
    content: "";
    display: block;
    height: 2px;
    width: 100px;
    background: #e7bb4b;
    right: 22%;
    top: 50%;
    position: absolute;
  }

  .ourProjects-title h1::after {
    content: "";
    display: block;
    height: 2px;
    width: 100px;
    background: #e7bb4b;
    left: 22%;
    top: 50%;
    position: absolute;
  }

  .ourProjects{
    margin-top: 0;
  }

}

@media only screen and (max-width: 600px) {
  .ourProjects {
    background-color: #fcfaf9;
    width: 100%;
    height: 100vh;
  }
  .ourProjects-wrapper {
    width: 100%;
    height: 100%;
    padding: 50px 0px 0px 0px;
  }

  .ourProjects-title {
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
  }

  .ourProjects-title h1 {
    color: #111111;
    font-size: 24px;
    line-height: 125%;
  }

  .ourProjects-title h1::before {
    content: "";
    display: block;
    height: 2px;
    width: 72px;
    background: #e7bb4b;
    right: 0%;
    top: 50%;
    position: absolute;
  }

  .ourProjects-title h1::after {
    content: "";
    display: block;
    height: 2px;
    width: 72px;
    background: #e7bb4b;
    left: 0%;
    top: 50%;
    position: absolute;
  }

  .ourProjects-subtitle {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    text-align: center;
  }

  .ourProjects-content-wrapper {
    display: grid;
    width: 100%;
    padding-top: 50px;
  }

  .ourProjects-categories {
    width: 100%;
    padding-top: 0px;
    position: relative;
    display: none;
  }

  .mobile-categiroies-title {
    display: block;
  }

  .ourProjects-categories-mobile {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  .ourPrejects-categories-mobile-contents {
    display: flex;
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .ourProjects-categories-mobile-item-active {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    width: 275px;
    height: 90px;
    background-color: #111111;
    border-radius: 8px;
    margin-right: 20px;
  }

  .ourProjects-categories-mobile-item-active img {
    display: block;
    margin-right: 5px;
  }

  .ourProjects-categories-mobile-item-active .number {
    margin-right: 5px;
    font-size: 32px;
    line-height: 150%;
    color: #d2d2d2;
  }

  .ourProjects-categories-mobile-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    width: 275px;
    height: 90px;
    border-radius: 8px;
    margin-right: 20px;
  }

  .ourProjects-categories-mobile-item img {
    display: none;
    margin-right: 0px;
  }

  .ourProjects-categories-mobile-item .number {
    margin-right: 30px;
    margin-left: -15px;
    font-size: 32px;
    line-height: 150%;
    color: rgba(17, 17, 17, 0.32);
  }

  .ourProjects-categories-mobile-item .ourProjects-categories-item-contents h3 {
    font-size: 12px;
    line-height: 150%;
    color: rgba(17, 17, 17, 0.32);
  }

  .ourProjects-categories-mobile-item .ourProjects-categories-item-contents h2 {
    font-size: 14px;
    line-height: 150%;
    color: rgba(17, 17, 17, 0.32);
  }

  .ourProjects-projects {
    width: 100%;
    margin-left: 0px;
    height: auto;
    background-color: #111111;
    border-radius: 8px;
  }

  .projects-wrapper {
    padding: 30px;
    display: grid;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .project-info {
    width: 100%;
    position: relative;
  }

  .project-info h2 {
    font-size: 20px;
    line-height: 125%;
    color: #ffffff;
  }

  .project-info h3 {
    font-size: 14px;
  }

  .project-info-details {
    display: flex;
    margin-top: 10px;
  }

  .project-info-details span {
    color: #828282;
    font-size: 12px;
    margin-right: 4px;
  }

  .project-info-btns {
    margin-top: 20px;
  }

  .project-info-btns .btn {
    padding: 15px 40px 15px 40px;
    margin-right: 10px;
    border-radius: 8px;
    background: rgba(17, 17, 17, 0.5);
    outline: 0px;
    border: 0px;
  }

  .project-info-bars {
    display: flex;
    position: relative;
    padding-top: 30px;
  }

  .bar-active {
    width: 68px;
    height: 4px;
    border-radius: 8px;
    background-color: #e7bb4b;
    margin-right: 5px;
  }

  .bar {
    width: 38px;
    height: 4px;
    border-radius: 8px;
    background-color: #828282;
    margin-right: 5px;
  }

  .project-pics {
    width: 100%;
    height: 100%;
    padding-top: 20px;
  }

  .project-pics .project-pic {
    width: 100%;
    height: 100%;
  }

  .project-pics .project-pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
}
