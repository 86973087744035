footer{
    background-color: #111;
    color: #fff;
    height: 290px;
    display: flex;
    align-items: center;
}


footer .logo{
    width: 200px;
}

footer .logo img{
    width: 100%;
}

footer ul{
    list-style: none;
}

footer a{
    color: #efefef;
    text-decoration: none;
    transition: .3s ease color;
}

footer a:hover{
    color: #fff;
}

footer .logo{
    filter: brightness(0) invert(1);
}

/* footer top */

.footer-top{
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-top .footer-left{
    display: flex;
    align-items: center;
}

.footer-top .footer-left .footer-left-info{
    display: flex;
    flex-direction: column;
    margin-left: 32px;
}

.footer-top .footer-left .footer-left-info a{
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 4px;
}

/* top right */

.footer-top .footer-right ul{
    display: flex;
}

.footer-top .footer-right ul li{
    margin-left: 40px;
}

.footer-top .footer-right ul li a{
    font-size: 14px;
    text-transform: uppercase;
}

/* footer bot  */

.footer-bot{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-bot .footer-left .footer-socials {
    display: flex;
}

.footer-bot .footer-left .footer-socials li{
    margin-right: 8px;
}

.footer-bot .footer-left .footer-socials li a{
    font-size: 20px;
}

/* footer bot right  */

.footer-bot .footer-right .footer-copy{
font-size: 14px;
}


@media only screen and (max-width: 980px) {

    footer{
        height: 445px;
    }

    footer a{
        color: #fff;
    }

    .footer-top{
        flex-direction: column;
    }

    .footer-top .footer-left{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

    }
    .footer-top .footer-right ul{
       text-align: center;
       flex-direction: column;
    }

    .footer-top .footer-right ul li{
        margin: 0;
        margin-top: 25px;
    }

    /* footer bot  */
    .footer-bot{
        flex-direction: column;
        width: 100%;
        margin-top: 50px;
    }
    .footer-bot .footer-left .footer-socials{
        margin-bottom: 10px;
    }

    .footer-bot .footer-left .footer-socials li a {
        font-size: 25px;
    }
    

}