.instagram-reels {
  padding: 100px 0;
}

.instagram-reels .faq-text {
  margin-top: 16px;
}

.instagram-contact-button {
  display: grid;
  place-items: center;
  margin-top: 30px;
}



/* reeels tab */

.instagram-reels-inner .container {
  background-color: #111;
  color: #fff;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
  position: relative;
  padding: 54px 0 54px 0;
}

/* reels-left */

.instagram-reels-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
}

.instagram-reels-left {
  margin-left: 105px;
}

.instagram-reels-right {
  margin-right: 200px;
}

.instagram-reels-right img:first-child {
  width: 340px;
  height: 730px;
  position: absolute;
  right: 30%;
  transform: translateX(70%);
  top: -40px;
}

.instagram-reels-right .phone-content{
  background-color: white;
  position: absolute;
  right: 29.5%;
  width: 305px;
  transform: translateX(70%);
  top: -20px;
  height: 690px;
  border-radius: 30px;
  display: none;
}

.instagram-reels-right .phone-content video{
  background-color: white;
  position: absolute;
  right: 70%;
  width: 305px;
  transform: translateX(70%);
  top: 0px;
  height: 690px;
  border-radius: 30px;
  object-fit: cover;
}

.instagram-reels-right .phone-content svg{
  position: absolute;
  right: 15px;
  bottom: 15px;
  z-index: 3;
}

.instagram-reels-right .phone-content-active {
  display: block;
}

.instagram-reels-tabs .instagram-reels-tab:last-child {
  border-bottom: 0;
}

.instagram-reels-tab {
  display: flex;
  align-items: center;
  padding: 35px 0;
  border-bottom: 2px solid rgba(231, 187, 75, 0.24);

  cursor: pointer;
}

.instagram-reels-tab img {
  display: none;
}

.instagram-reels-tab-active img {
  display: block;
}

.instagram-reels-tab-left {
  margin-right: 16px;
  display: flex;
  align-items: center;
}

.instagram-reels-tab-left img {
  margin-right: 16px;
}

.instagram-reels-tab-left span {
  font-size: 32px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.32);
}

.instagram-reels-tab{
  user-select: none;
  width: 60%;
}

.instagram-reels-tab-active .instagram-reels-tab-left span {
  color: #fff;
}

.instagram-reels-tab-right {
  display: flex;
  flex-direction: column;
}

.instagram-reels-tab-right span:first-child {
  font-size: 12px;
  font-weight: 400;

  color: rgba(255, 255, 255, 0.32);
}

.instagram-reels-tab-active .instagram-reels-tab-right span:first-child {
  color: #828282;
}

.instagram-reels-tab-right span:last-child {
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.32);
}

.instagram-reels-tab-active .instagram-reels-tab-right span:last-child {
  color: #fff;
}

.dn{
  display: none;
}

.dp{
  display: block;
}

/* responsive */

@media only screen and (max-width: 1350px) {
  .instagram-reels-right .phone-content {
    right: 29.3%;
  }
}

@media only screen and (max-width: 1400px) {
  .instagram-reels .main-page-title{
    width: 300px;
  }
  
  .instagram-reels .main-page-title::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0%;
    background-color: #e7bb4b;
    height: 2px;
    width: 50px;
  }
  .instagram-reels .main-page-title::before {
    content: "";
    position: absolute;
    right: 0%;
    top: 50%;
    background-color: #e7bb4b;
    height: 2px;
    width: 50px;
  }
  .instagram-reels-inner .container {
    flex-direction: column;
    padding: 54px 0 600px 0;
    align-items: flex-start;
  }
  .instagram-reels-tab{
    user-select: none;
    width: 100%;
  }

  .instagram-reels-left {
    margin: 0;
    width: 100%;
    padding: 0 20px;
  }

  .instagram-reels-right img:first-child {
    right: 50%;
    transform: translateX(50%);
    top: 55%;
  }

  .instagram-reels-right .phone-content {
    right: 50%;
    width: 305px;
    transform: translateX(50%);
    top: 56.7%;
    height: 690px;
  }

  .instagram-reels-tabs {
    display: flex;
    flex-direction: column-reverse;
  }

  .instagram-reels {
    padding: 200px 0;
  }

  .instagram-reels-tabs .instagram-reels-tab:last-child {
    border-bottom: 2px solid rgba(231, 187, 75, 0.24);
  }
  .instagram-reels-tabs .instagram-reels-tab:first-child {
    border-bottom: 0;
  }
}

@media only screen and (max-width: 600px) {
  .instagram-reels-inner .container {
    padding: 54px 0 350px 0;

  }
  .instagram-reels-right img:first-child {
    width: 220px;
    height: 440px;
    right: 50%;
    transform: translateX(50%);
    top: 75%;
  }

  .instagram-reels-right .phone-content {
    right: 50%;
    width: 200px;
    transform: translateX(50%);
    top: 76%;
    height: 420px;
  }
  .instagram-reels-right .phone-content video{
    background-color: white;
    position: absolute;
    right: 70%;
    width: 200px;
    transform: translateX(70%);
    top: 0px;
    height: 420px;
    border-radius: 30px;
    object-fit: cover;
  }
  
}
