.top-to-btm{
    position: relative;
  }
  .icon-position{
    position: fixed;
    bottom: 90px;
    right: 25px;
    z-index: 20;
  }
  .icon-style{
    background-color: rgb(101, 100, 100);
    border: 2px solid #fff;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    color: #fff;
    cursor: pointer;
    transition: all .2s ease-in-out;
  }
  .icon-style:hover{
    animation: none;
    background: #fff;
    color: #e7bb4b;
    border: 2px solid rgb(101, 100, 100);
  }
  
  @media only screen and (max-width: 750px) {
    .icon-position{
        right: 10px;
      }
  }