/* contact us modal */
.contact-us-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999999;
  display: grid;
  place-items: center;
  transition: 0.3s ease all;
  overflow-y: auto;
  overflow-x: hidden;
}


.contact-us {
  width: 635px;
  height: 790px;
  background-color: #fff;
  border-radius: 16px;
  padding: 0 74px;
  z-index: 4;
}

.contact-us-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 3;
}

.contact-us-content {
  margin-top: 65px;
}

.contact-us-title {
  font-size: 48px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 16px;
  position: relative;
}

.contact-us-title::before {
  content: "";
  position: absolute;
  left: -32px;
  top: 50%;
  background-color: #e7bb4b;
  width: 51px;
  height: 2px;
}
.contact-us-title::after {
  content: "";
  position: absolute;
  right: -32px;
  top: 50%;
  background-color: #e7bb4b;
  width: 51px;
  height: 2px;
}

.contact-us-text {
  font-weight: 200;
  font-size: 13px;
  text-align: center;
}

.contact-us-closebtn {
  position: relative;
  background-color: #fff;
  left: 320px;
  top: 20px;
  width: 31px;
  height: 31px;
  display: grid;
  place-items: center;
  border-radius: 100%;
  cursor: pointer;
  z-index: 4;
}

.contact-us-closebtn span {
  transform: scaleX(2);
  font-weight: 200;
  font-size: 14px;
}

.contact-us form {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
}

.contact-us form label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 200;
  margin-bottom: 16px;
}

.contact-us form input[type="text"],
.contact-us form input[type="tel"],
.contact-us form input[type="email"] {
  border: 1.5px solid #d9d9d9;
  border-radius: 8px;
  padding: 11px 16px;
  margin-top: 2px;
}

.contact-us form input[type="text"]:focus,
.contact-us form input[type="tel"]:focus,
.contact-us form input[type="email"]:focus {
  outline: 1.5px solid #e7bb4b;
}

.contact-us form textarea {
  border: 1.5px solid #d9d9d9;
  border-radius: 8px;
  padding: 11px 16px;
  margin-top: 2px;
  resize: none;
}

.contact-us form textarea:focus {
  outline: 1.5px solid #e7bb4b;
}

.contact-us form input[type="submit"] {
  margin-left: auto;
  margin-top: 10px;
}

.contact-us .PhoneInputCountry{
 border-radius: 8px;
 border: 1.5px solid #d9d9d9;
 padding: 0px 16px;
 height: 40px;
 margin-top: auto;

}

.error-message {
  display: none;
}

.error-message-active {
  display: block;
  font-size: 15px;
  margin-top: 10px;
  color: red;
  font-weight: normal;
  text-align: center;
}


/* info popUp */
.info-popUp{
  background-color: #fff;
  position: absolute;
  right: -200%;
  bottom: 0;
  padding: 20px 30px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  transition: .3s ease all;
}

.info-popUp-active{
  animation-name: info-popUp;
  animation-duration: 8s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-direction: alternate;
}


@keyframes info-popUp {
  0%{
    right: -100%;
  }
  10%{
    right: 0;
  }
  20%{
    right: 0;
  }
  30%{
    right: 0;
  }
  40%{
    right: 0;
  }
  50%{
    right: 0;
  }
  60%{
    right: 0;
  }
  70%{
    right: 0;
  }
  80%{
    right: 0;
  }
  90%{
    right: 0;
  }
  100%{
    right: -100%;
  }
}


.info-popUp-title{
  font-size: 15px;
  font-weight: 500;
}

.info-popUp-text{
  font-size: 14px;
}

.info-popUp span{
  font-size: 30px;
  margin-right: 20px;
}

/* responsive */
@media only screen and (max-width: 650px) {
  .contact-us {
    width: 340px;
    height: 665px;
    padding: 0 12px;
  }

  .contact-us-content{
    margin-top: 18px;
  }
  .contact-us-title {
    font-size: 24px;
  }
  .contact-us-title::before {
    left: 0;
    width:51px;
  }
  .contact-us-title::after {
    right: 0px;
    width: 51x;
  }
  .contact-us-text{
    font-size: 11px;
    font-weight: 300;
  }

  .contact-us-closebtn {
    left: 180px;
    top: 70px;
  }
}

/* New Modal */
.new-modal {
  width: 70%;
  background-color: #fff;
  border-radius: 8px;
  z-index: 4;
}

.new-modal-image {
  width: 100%;
  height: 40%;
}

.new-modal-image img {
  width: 100%;
  height: 100%;
  border-radius: 8px 8px 0px 0px;
}

.new-modal-contents {
  width: 100%;
  height: 60%;
  display: grid;
  align-items: center;
  padding: 55px;
}

.new-modal-contents h2 {
  line-height: 52px;
  font-size: 44px;
  font-weight: bold;
  color: #111111;
  margin-bottom: 32px;
  text-align: center;
}

.new-modal-contents h2 strong {
  color: #e7bb4b;
}

.new-modal-contents h3 {
  line-height: 32px;
  font-size: 24px;
  color: #111111;
  margin-bottom: 24px;
  text-align: center;
  font-weight: 300;
}

.new-modal-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-modal-btn-wrapper button:first-child {
  margin-right: 15px;
}

.new-modal-btn-wrapper button {
  min-width: 175px;
  padding: 15px 20px;
  background-color: #e7bb4b;
  border: 0px;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.progress h2 {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #111111;
}

.progress h2 strong {
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #848484;
}

.progress-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.progress-bar .active-bar {
  width: 80px;
  height: 4px;
  border-radius: 8px;
  background-color: #e7bb4b;
  margin-right: 10px;
}

.progress-bar .bar {
  width: 80px;
  height: 4px;
  border-radius: 8px;
  background-color: hsla(0,0%,59.2%,.35);
  margin-right: 10px;
}

.new-modal-title {
  margin-top: 15px;
  margin-bottom: 15px;
}

.new-modal-title h2 {
  font-size: 44px;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.buttons .back-btn {
  margin-right: 10px;
  border: 1px solid #e7bb4b;
  background-color: #FFFFFF;
  color: #e7bb4b;
  box-shadow: none;
  padding: 14px 20px;
  transition: all .5s ease;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
}

.buttons .next-btn {
  margin-right: 10px;
  color: #fff;
  background-color: #e7bb4b;
  border: 0px;
  box-shadow: none;
  padding: 14px 20px;
  transition: all .5s ease;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
}

.new-modal-big {
  display: block;
  border-radius: 8px;
}
.new-modal-small {
  display: none;
  border-radius: 0px !important;
}

/* checkbox */
.new-modal-form {
  display: block;
  justify-content: center;
  align-items: center;
}

.new-modal-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-modal-flex:last-child {
  margin-top: 15px;
}

input[type = checkbox] {
  box-sizing: border-box;
  padding: 0;
}

.type-answers {
  display: block;
  cursor: pointer;
  margin-right: 15px;
}

.type {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  white-space: nowrap;
  border: 0;
}

.type-answer-title {
  display: block;
  padding: 18px 60px 18px 60px;
  background: #fff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 12px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #e7bb4b;
  transition: all .5s ease;
}

.type-answers input:checked+.type-answer-title {
  border: 1px solid #e7bb4b;
}
/* checkbox */

.unvisibble-input {
  display: none;
}
@media only screen and (max-width: 650px) {
  .new-modal {
    width: 100%;
    background-color: #fff;
    border-radius: 0px;
    z-index: 4;
  }

  .new-modal-big {
    display: none;
    border-radius: 0px;
  }
  .new-modal-small {
    display: block;
    border-radius: 0px !important;
  }

  .new-modal-contents {
    width: 100%;
    /* height: 60%; */
    display: grid;
    align-items: center;
    padding: 5px;
  }

  .new-modal-contents h2 {
    font-size: 18px;
    line-height: 24px;
  }
  .new-modal-contents h3 {
    font-size: 14px;
    line-height: 18px;
  }

  .progress h2 strong {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #848484;
  }

  .progress-bar .bar {
    width: 50px;
    height: 4px;
    border-radius: 8px;
    background-color: hsla(0,0%,59.2%,.35);
    margin-right: 10px;
  }

  .progress-bar .active-bar {
    width: 50px;
    height: 4px;
    border-radius: 8px;
    background-color: #e7bb4b;
    margin-right: 10px;
  }

  .type-answer-title {
    display: block;
    padding: 15px 10px 15px 10px;
    background: #fff;
    border: 1px solid #efefef;
    box-sizing: border-box;
    border-radius: 12px;
    text-align: center;
    font-weight: 500;
    font-size: 11px;
    line-height: 24px;
    color: #e7bb4b;
    transition: all .5s ease;
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 10px;
  }

  .contact-us form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  
}
/* New Modal */