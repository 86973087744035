/* citizenship */
.citizenship-inner {
  padding: 48px;
  background-color: #fcfaf9;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 20%;
  border-radius: 8px;
}

.citizenship-title {
  font-size: 40px;
  font-weight: 500;
}

.citizenship-text {
  font-size: 14px;
  font-weight: 300;
  margin: 16px 0 40px 0;
  color: #828282;
}

.citizenship-item {
  font-size: 16px;
  font-weight: 300;
  color: #828282;
  display: flex;
  position: relative;
  margin-bottom: 25px;
  padding-left: 16px;
}

.citizenship-item::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 8px;
  background-color: #e7bb4b;
}

.citizenship-button button {
  margin-top: 20px;
  width: 165px;
  height: 50px;
}

.citizenship-images {
  position: relative;
  height: 442px;
  width: 568px;
  margin-left: auto;
}

.citizenship-images::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40%;
  height: 40%;
  background-image: url("../../../public/images/citizenship-circle.png");
}

.citizenship-images img {
  position: absolute;
  width: 368px;
  height: 297px;
  transition: 0.4s ease all;
}

.citizenship-images img:first-child {
  right: 0;
  top: 0;
}

.citizenship-images img:last-child {
  left: 0;
  bottom: 0;
}

.citizenship-images:hover img:first-child {
  right: 200px;
  top: 145px;
  z-index: 1;
}
.citizenship-images:hover img:last-child {
  left: 200px;
  bottom: 145px;
}
.citizenship-images:focus img:first-child {
  right: 200px;
  top: 145px;
  z-index: 1;
}
.citizenship-images:focus img:last-child {
  left: 200px;
  bottom: 145px;
}

@media only screen and (max-width: 1440px) {
  .citizenship-inner {
    gap: 10%;
  }
  .citizenship-title {
    font-size: 24px;
  }

  .citizenship-text {
    font-size: 12px;
  }

  .citizenship-item {
    font-size: 12px;
  }
  .citizenship-images {
    height: 237px;
    width: 308px;
  }

  .citizenship-images img {
    position: absolute;
    width: 207px;
    height: 160px;
    transition: 0.4s ease all;
  }

  .citizenship-images:hover img:first-child {
    right: 101px;
    top: 77px;
    z-index: 1;
  }
  .citizenship-images:hover img:last-child {
    left: 101px;
    bottom: 77px;
  }
  .citizenship-images:focus img:first-child {
    right: 101px;
    top: 77px;
    z-index: 1;
  }
  .citizenship-images:focus img:last-child {
    left: 101px;
    bottom: 77px;
  }
}

@media only screen and (max-width: 820px) {
  .citizenship-inner {
    display: flex;
    flex-direction: column;
  }
  .citizenship-images {
    margin-left: 0;
    margin-top: 50px;
  }
}

/* page title */
.page-title {
  padding: 150px 0;
}

.page-title .container::before {
  content: "";
  position: absolute;
  left: 0;
  top: -70%;
  height: 3px;
  width: 30%;
  background-color: #e7bb4b;
}
.page-title .container::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -70%;
  height: 3px;
  width: 30%;
  background-color: #e7bb4b;
}

.page-title .container {
  display: grid;
  place-items: center;
}

.page-title-inner {
  text-align: center;
  font-size: 32px;
  font-weight: 500;
}

@media only screen and (max-width: 920px) {
  .page-title .container::before {
    width: 15%;
  }
  .page-title .container::after {
    width: 15%;
  }

  .page-title-inner {
    font-size: 25px;
  }
}

@media only screen and (max-width: 600px) {
  .page-title-inner {
    font-size: 16px;
  }
}

/* why choose us */
.whyChooseUs-inner {
  padding: 48px;
  background-color: #fcfaf9;
  display: grid;
  border-radius: 8px;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 20%;
}

.whyChooseUs-title {
  font-size: 40px;
  font-weight: 500;
}

.whyChooseUs-text {
  font-size: 14px;
  font-weight: 300;
  margin: 16px 0 40px 0;
  color: #828282;
}

.whyChooseUs-item {
  font-size: 16px;
  font-weight: 300;
  color: #828282;
  display: flex;
  position: relative;
  margin-bottom: 25px;
  padding-left: 16px;
}

.whyChooseUs-item::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 8px;
  background-color: #e7bb4b;
}

.whyChooseUs-button button {
  margin-top: 20px;
  width: 165px;
  height: 50px;
}

.whyChooseUs-images {
  position: relative;
  height: 442px;
  width: 568px;
  margin-left: auto;
}

.whyChooseUs-images::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 40%;
  height: 40%;
  background-image: url("../../../public/images/citizenship-circle.png");
}

.whyChooseUs-images img {
  position: absolute;
  width: 368px;
  height: 297px;
  transition: 0.4s ease all;
}

.whyChooseUs-images img:first-child {
  left: 0;
  top: 0;
}

.whyChooseUs-images img:last-child {
  right: 0;
  bottom: 0;
}

.whyChooseUs-images:hover img:first-child {
  left: 200px;
  top: 145px;
  z-index: 1;
}
.whyChooseUs-images:hover img:last-child {
  right: 200px;
  bottom: 145px;
}
.whyChooseUs-images:focus img:first-child {
  left: 200px;
  top: 145px;
  z-index: 1;
}
.whyChooseUs-images:focus img:last-child {
  right: 200px;
  bottom: 145px;
}

@media only screen and (max-width: 1440px) {
  .whyChooseUs-inner {
    gap: 10%;
  }
  .whyChooseUs-title {
    font-size: 24px;
  }

  .whyChooseUs-text {
    font-size: 12px;
  }

  .whyChooseUs-item {
    font-size: 12px;
  }
  .whyChooseUs-images {
    height: 237px;
    width: 308px;
  }

  .whyChooseUs-images img {
    position: absolute;
    width: 207px;
    height: 160px;
    transition: 0.8s ease all;
  }

  .whyChooseUs-images:hover img:first-child {
    left: 101px;
    top: 77px;
    z-index: 1;
  }
  .whyChooseUs-images:hover img:last-child {
    right: 101px;
    bottom: 77px;
  }
  .whyChooseUs-images:focus img:first-child {
    left: 101px;
    top: 77px;
    z-index: 1;
  }
  .whyChooseUs-images:focus img:last-child {
    right: 101px;
    bottom: 77px;
  }
}

@media only screen and (max-width: 820px) {
  .whyChooseUs-inner {
    display: flex;
    flex-direction: column-reverse;
  }
  .whyChooseUs-images {
    margin-left: 0;
    margin-top: 50px;
  }
}
