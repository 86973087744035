.hero {
  display: inline-block;
  width: 100%;
  height: 100vh;
  background-image: url("../../../public/images/hero-bg.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  overflow: hidden;
  margin-top: -109px;
  position: relative;
}

.hero-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 10%
  );
}

.hero-inner {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  align-items: center;
  height: 100%;
  position: relative;
}

.hero-right {
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.hero-right img {
  margin-left: auto;
}

.hero-left {
  height: 100%;
  display: grid;
  place-items: center;
  z-index: 2;
}

.hero-content {
  padding: 0 10%;
}

.hero-title {
  font-size: 64px;
  font-weight: 500;
}

.hero-title span {
  color: #e7bb4b;
}

.hero-text1 {
  font-weight: 300px;
  font-size: 16px;
  color: #828282;
}
.hero-text2 {
  font-weight: 300px;
  font-size: 12px;
  color: #828282;
  margin-top: 10px;
}



.hero-content .primary-button:first-of-type {
  margin-right: 32px;
  margin-top: 40px;
}

.hero-istatistics {
  display: flex;
  margin-top: 90px;
}

.hero-istatistic {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  align-items: center;
}

.hero-istatistic > span:first-child {
  font-weight: 500;
  font-size: 24px;
}

.hero-istatistic > span > span {
  color: #e7bb4b;
  margin-left: -5px;
}

.hero-istatistic > span:last-child {
  font-weight: 300;
  font-size: 12px;
  color: #828282;
  margin-top: -5px;
  margin-right: 10px;
}

.clouds {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  
}

.clouds img {
  position: absolute;
  bottom: 0;
  width: 50%;
  animation: cloud-animation calc(20s * var(--i)) linear infinite;
  
}

@media only screen and (max-width: 1240px) {
  .hero {
    height: auto;
  }
  .hero-overlay {
    height: 780px;
  }

  .hero-inner {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .hero-right {
    margin-left: auto;
  }
  .hero-right img {
    width: 100%;
  }
  .hero-left {
    background-color: #fff;
    width: 100%;
    display: flex;
    padding: 0;
  }

  .hero-content {
    padding: 0 5%;
    width: 100%;
  }

  .hero-title {
    margin-top: -50px;
  }

  .hero-istatistics {
    justify-content: space-between;
    margin-top: 55px;
  }

  .hero-istatistic {
    margin-right: 0;
  }

  .clouds img {
    bottom: 290px;
  }
}

@media only screen and (max-width: 800px) {
  .hero {
    background-position: top top;
  }
  .hero-right img {
    width: 334px;
  }

  .hero-title {
    font-size: 32px;
  }

  .hero-text1 {
    font-size: 12px;
  }
  .hero .primary-button {
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
  }

  .hero-istatistic > span:first-child {
    font-size: 20px;
  }

  .hero-overlay {
    height: 390px;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 20%
    );
  }

  .clouds img {
    bottom: 320px;
  }
}

@media only screen and (max-width: 600px) {
  .hero-right img {
    animation-name: hero-image-animation;
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-delay: 2s;
    animation-fill-mode: forwards;
  }

  .hero-overlay {
    animation-name: hero-animation;
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-delay: 2s;
    animation-fill-mode: forwards;
  }
  .hero-right {
    animation-name: hero-animation;
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-delay: 2s;
    animation-fill-mode: forwards;
  }
  .clouds img {
    animation: cloud-animation calc(20s * var(--i)) linear infinite;
    width: 65%;
    bottom: 305px;
  }
}

@media only screen and (max-width: 420px) {
  .clouds img {
    bottom: 365px;
  }
}
@media only screen and (max-width: 376px) {
  .clouds img {
    bottom: 385px;
  }
}

/* animation */
@keyframes hero-animation {
  0% {
    height: 390px;
  }
  100% {
    height: 200px;
  }
}
@keyframes hero-image-animation {
  0% {
    width: 334px;
    height: 387px;
  }
  100% {
    width: 100%;
    height: 300px;
  }
}

@keyframes Fade-inup {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
  }
}

@keyframes cloud-animation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(250%);
  }
}
