.find-property {
  width: 100%;
  height: 505px;
  color: #fff;
  display: grid;
  place-items: center;
}

.find-property img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.find-property .container {
  display: grid;
  place-items: center;
}

.find-property-inner {
  position: relative;
}

.find-property .find-property-content {
  position: absolute;
  text-align: center;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
}

.find-property .find-property-content .find-property-title {
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 25px;
  text-align: center;
  position: relative;
}
.find-property .find-property-content .find-property-title::before {
  content: "";
  position: absolute;
  left: -30%;
  top: 30px;
  background-color: #fff;
  width: 30%;
  height: 3px;
}
.find-property .find-property-content .find-property-title::after {
  content: "";
  position: absolute;
  right: -30%;
  bottom: 30px;
  background-color: #fff;
  width: 30%;
  height: 3px;
}

.find-property .find-property-content .find-property-text {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 25px;
  text-align: center;
}

.find-property-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.38) 0%,
    rgba(22, 20, 19, 0.9) 80.73%
  );
  border-radius: 8px;
}

.find-property-button .primary-button {
  background: linear-gradient(180deg, #f0d798 0%, #e7bb4b 74.42%);
  padding-bottom: 5px;
  box-sizing: content-box;
}

@media only screen and (max-width: 1250px) {
  .find-property .find-property-content .find-property-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 950px) {
  .find-property .find-property-content .find-property-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 650px) {
  .find-property {
    height: 320px;
  }
  .find-property .find-property-content .find-property-title {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .find-property img {
    width: 100%;
    height: 280px;
    object-fit: cover;
  }
  .find-property .find-property-content .find-property-text {
    font-size: 12px;
  }

  .find-property .find-property-content {
    top: 30px;
  }
  .find-property .find-property-content .find-property-title::before {
    top: 10px;
    background-color: #fff;
    width: 20%;
  }
  .find-property .find-property-content .find-property-title::after {
    bottom: 10px;
    width: 20%;
  }
}
