
.faq{
  padding: 90px 0;
}

.faq-text {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.04em;
  text-align: center;
  color: #828282;
}

.accordion {
  margin-top: 60px;
  border-radius: 0;
  border: 0;
  background-color: #fcfaf9;
  padding: 20px 0 50px 0;
}

.accordion-item {
  padding: 0 100px;
  border: 0;
}

.title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.04em;
  color: #111 !important;
  background-color: transparent !important;
  padding: 0 !important;
  padding: 20px 0px !important;
}

.title::after {
  content: "" !important;
  width: 32px !important;
  height: 32px !important;
  background-image: url('../../../public/images/icons/faq-plus.png');
}

.accordion .active .title::after{
  background-image: url('../../../public/images/icons/faq-minus.png');
}

.panel {
  border-top: 2px solid rgba(17, 17, 17, 0.16) !important;
}


.panel > * {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.04em;
  text-align: left;
  color: #828282;
}

/* responsive */

@media only screen and (max-width: 920px) {
  .main-title {
    font-size: 32px;
  }
  .main-title::after{
    left: -20px;

  }
  .main-title::before{
    right: -20px;

  }

  .title {
    font-size: 14px;
  }

  .panel > * {
    font-size: 12px;
  }

  .accordion{
    background-color: transparent;
  }

  .accordion-item{
    padding: 0;
  }
  .faq-wrapper{
    background-color: #FCFAF9;
  }

  .title::after {
    background-image: url('../../../public/images/icons/faq-plus-l.png');
    width: 24px !important;
    height: 24px !important;
  }
  .accordion .active .title::after{
    background-image: url('../../../public/images/icons/faq-minus-l.png');
  }
  
}

@media only screen and (max-width: 550px) {
  .faq{
    padding-bottom: 0px;
  }
  .title {
    width: 90% !important;
    font-weight: 600;
  }
  .title::after {
    position: relative;
    left: 30px !important;
    top: -25%;
  }

  .faq-content{
    padding: 0 10px;
  }
}