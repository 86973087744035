.services {
  padding: 50px 0;
  margin-top: 100px;
}

.services-texts {
  display: grid;
  place-items: center;
  margin-bottom: 60px;
}

.services-title {
  font-size: 48px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 16px;
  position: relative;
}

.services-title::before {
  content: "";
  position: absolute;
  width: 102px;
  height: 2px;
  background-color: #eed080;
  left: -80%;
  top: 50%;
  display: block;
}
.services-title::after {
  content: "";
  position: absolute;
  width: 102px;
  height: 2px;
  background-color: #eed080;
  right: -80%;
  top: 50%;
  display: block;
}

.services-text {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.services-grid .services-card * {
  border-radius: 8px;
}

.services-card {
  position: relative;
  overflow: hidden;
}

.services-card-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) -20%,
    rgba(255, 255, 255, 0) 100%
  );
  transition: 0.3s ease all;
}

.services-card img {
  width: 100%;
  object-fit: cover;
  height: 400px;
  margin-bottom: -10px;
}
.services-card .services-card-content {
  position: absolute;
  bottom: 0;
  padding: 0 24px;
}

.services-card-title {
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
}

.services-card-text {
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  margin-top: -60px;
  width: 280px;
  transition: 0.3s ease all;
  margin-bottom: 0;
  transform: translateY(100%);
}

.services-grid
  .services-card:nth-child(2)
  .services-card-content
  .services-card-text {
  margin-top: -85px;
}
.services-grid
  .services-card:nth-child(3)
  .services-card-content
  .services-card-text {
  margin-top: -90px;
}
.services-grid
  .services-card:nth-child(4)
  .services-card-content
  .services-card-text {
  margin-top: -40px;
}
.services-grid
  .services-card:nth-child(5)
  .services-card-content
  .services-card-text {
  margin-top: -90px;
}
.services-grid
  .services-card:nth-child(6)
  .services-card-content
  .services-card-text {
  margin-top: -50px;
}

.services-card:hover .services-card-text {
  margin-top: 10px !important;
  margin-bottom: 0;
  transform: translateY(-10px);
}
.services-card:hover .services-card-overlay {
  opacity: 0.7;
}

@media only screen and (max-width: 1280px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 900px) {
  .services-card-title {
    font-size: 16px;
  }

  .services-card-text {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 700px) {
  .services-grid {
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
  }
  .services-card-text {
    margin-top: 0px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }

  .services-card-title {
    text-align: center;
  }
}

@media only screen and (max-width: 800px) {
  .services {
    margin-top: 250px;
  }
}

@media only screen and (max-width: 600px) {
  .services-grid {
    grid-template-rows: repeat(6, 211px);
  }
  .services {
    margin-top: 320px;
  }

  .services-card-text {
    margin-top: 10px !important;
    margin-bottom: 0;
    transform: translateY(-10px);
  }

  .services-card img {
    height: auto;
  }
}
