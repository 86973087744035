/*whatsapp*/
/*whatsapp widget*/
.whtsp-ac {
  position: fixed;
  bottom: 25px;
  right: 25px;
  padding: 10px;
  border-radius: 100%;
  padding: 10px 12px;
  padding-top: 12px;
  line-height: 30px;
  text-align: center;
  font-size: 32px;
  background: #128c7e;
  color: #fff;
  z-index: 999;
  cursor: pointer;
}

.whtsp-ac svg {
  width: 30px;
  height: 30px;
  fill: #fff;
  position: relative;
  top: 0px;
}

.yaziyo {
  position: fixed;
  bottom: 65px;
  right: 63px;
  background: #efefef;
  padding: 2px 12px;
  border-radius: 17px 17px 0px;
  z-index: 999;
  padding-top: 0px;
}
.sil {
  display: none !important;
}
.goster {
  display: block !important;
}
div#wave {
  position: relative;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
div#wave .dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 3px;
  background: #303131;
  animation: wave 1.3s linear infinite;
}
div#wave .dot:nth-child(2) {
  animation-delay: -1.1s;
}
div#wave .dot:nth-child(3) {
  animation-delay: -0.9s;
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }
  30% {
    transform: translateY(-5px);
  }
}

.whatsapp-wrap {
  background-image: url("https://cosylifeconsulting.com/wp-content/uploads/2022/05/whtsp-bg.jpg") !important;
  background-repeat: no-repeat;
  background-size: cover !important;
  position: fixed;
  right: 25px;
  bottom: 95px;
  width: 350px;
  height: 280px;
  background: #fff;
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 24px 0px rgba(7, 94, 84, 1);
  -moz-box-shadow: 0px 0px 24px 0px rgba(7, 94, 84, 1);
  box-shadow: 0px 0px 24px 0px rgba(7, 94, 84, 1);
  z-index: 99999999;
}
.whatsapp-tepe {
  background: #075e54;
  color: #fff;
  padding: 10px;
  height: 70px;
  border-radius: 13px 13px 0px 0px;
  -webkit-box-shadow: 0px 11px 19px -14px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 11px 19px -14px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 11px 19px -14px rgba(0, 0, 0, 0.75);
}

.w-profil {
  float: left;
}

.w-profil img {
  width: 50px;
  border-radius: 50px;
}
.w-isim {
  position: relative;
  left: 15px;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  top: 6px;
}
.w-isim span {
  display: block;
  font-size: 14px;
  font-weight: normal;
}
.w-chat {
  width: 90%;
  float: left;
  padding: 20px;
  margin-top: 2px;
}

.w-chat li {
  float: right;
  font-size: 14px;
  background: #dcf8c6;

  width: fit-content;
  position: relative;
  color: #222;

  border-radius: 12px 0px 12px 12px;
  margin-bottom: 11px;
  -webkit-box-shadow: 0px 0px 14px -7px rgb(0 0 0 / 75%);
  -moz-box-shadow: 0px 0px 14px -7px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 14px -7px rgb(0 0 0 / 75%);
}
.w-chat li:hover {
  background: #c5f79e;
}

.w-chat li {
  display: block;
}

.w-chat li a {
  padding: 10px;
  display: block;
  text-decoration: none;
  color: #222;
}

.w-chat li:first-child {
  float: left;
  background: #fff;
  cursor: normal;
  border-radius: 0px 12px 12px 12px;
  cursor: pointer;
}

.w-chat li:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 13px 13px 0px 0;
  border-color: #dcf8c6 transparent transparent transparent;
  position: absolute;
  right: -13px;
  top: 0px;
}

.w-chat li:first-child:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0px 13px 13px 0;
  border-color: transparent #fff transparent transparent;
  position: absolute;
  left: -13px;
  top: 0px;
}

.whatsapp-vuct svg {
  width: 20px;
  width: 20px;
  height: 20px;
  fill: #128c7e;
  float: right;
  bottom: 20px;
  position: absolute;
  right: 15px;
  cursor: pointer;
}
/*whatsapp widget*/
/*whatsapp*/

@media only screen and (max-width: 750px) {

.whtsp-ac {
  right: 10px;
}
}

@media only screen and (max-width: 600px) {
  .whatsapp-wrap{
    width: calc(100% - 50px);
  }

}
